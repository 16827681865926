import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwiperModule } from 'swiper/angular';

import { take } from 'rxjs/operators';

import { initializeApp } from 'firebase/app';
import { initializeAuth, indexedDBLocalPersistence, inMemoryPersistence, Auth, signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { initializeFirestore, Firestore, getFirestore, collection, query } from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { environment } from 'src/environments/environment';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppService } from './core/app.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { AuthService } from '@core/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavOptions } from '@ionic/core';
import { createAnimation } from '@ionic/angular';

export interface TransitionOptions extends NavOptions {
  progressCallback?: ((ani: Animation | undefined) => void);
  baseEl: any;
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
}

export const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(
    ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
  );
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates
  // and we don't have a null pointer
  return element;
};

export function pageTransition(_: HTMLElement, opts: TransitionOptions) {
  const DURATION = 300;

  // root animation with common setup for the whole transition
  const rootTransition = createAnimation()
    .duration(opts.duration || DURATION)
    .easing('cubic-bezier(0.3,0,0.66,1)');

  // ensure that the entering page is visible from the start of the transition
  const enteringPage = createAnimation()
    .addElement(getIonPageElement(opts.enteringEl))
    .beforeRemoveClass('ion-page-invisible');

  // create animation for the leaving page
  const leavingPage = createAnimation().addElement(
    getIonPageElement(opts.leavingEl)
  );

  // actual customized animation
  if (opts.direction === 'forward') {
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
    leavingPage.fromTo('opacity', '1', '0.25');
  } else {
    leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)');
    enteringPage.fromTo('opacity', '0.25', '1');
  }

  // include animations for both pages into the root animation
  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({navAnimation: pageTransition}),
    AppRoutingModule,
    CommonModule,
    GoogleMapsModule,
    SwiperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    // provideFirestore(() => getFirestore()),
    // provideFunctions(() => getFunctions()),
    // provideMessaging(() => getMessaging()),
    // provideStorage(() => getStorage()),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => {
        return () => new Promise<void>(async (resolve, reject) => {
          // initialize google maps
          const app = initializeApp(environment.firebase);
          // set dark mode
          const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          if (darkMode) {
            document.body.classList.toggle('dark', darkMode);
          }
          if (location.hostname === 'localhost') {
            connectFunctionsEmulator(getFunctions(app), '192.168.1.68', 5001);
            // connectStorageEmulator(getStorage(app), 'localhost', 9199);
          }
          await appService.appInit(app);
          console.log('after app service init');
          // await geoService.appInit();
          // await sessionService.appInit();
          resolve();
        });
      },
      multi: true,
      deps: [AppService] // keep the RouterService in deps so that it will load on init
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
