import { TCollection, TAvatarPathCollection } from './common.model';
import { IUserProfile, IUser, IUserAccount, User, UserProfile, UserAccount } from './user.model';
import { Entity } from './entity.model';
import { ITag, Tag, TagIcon, ITagIcon } from './tag.model';
import { IOrg, IOrgProfile, IOrgAccount, Org, OrgProfile, OrgAccount, IOrgUser, OrgUser } from './org.model';
// import { ICommunity, Community, ICommunityProfile, CommunityProfile, ICommunityAccount, CommunityAccount, ICommunityMember, CommunityMember } from './community.model';
import { Session, ISession } from './session.model';
// import { IConversation, IMessage, Conversation, Message } from './messaging.model';
// import { INeed, Need } from './need.model';
// import { IResource, Resource } from './resource.model';

export type TIEntity =
IUserProfile | IUser | IUserAccount
| ITag | ITagIcon
| ISession
| IOrg | IOrgProfile | IOrgAccount | IOrgUser
// | ICommunity | ICommunityProfile | ICommunityAccount | ICommunityMember
// | INeed | IResource
// | IConversation | IMessage
;

export type TEntity =
User | UserProfile | UserAccount
| Tag | TagIcon
| Session
| Org | OrgProfile | OrgAccount | OrgUser
// | Community | CommunityProfile | CommunityAccount | CommunityMember
// | Need | Resource
// | Conversation | Message
;

export type TAvatarEntity = User 
// | Org 
// | Need 
// | Resource
;

export interface ToEntityOptions {
  avatar?: boolean;
  thumbnail?: boolean;
  banner?: boolean;
}

export const fromDoc = <T extends TEntity>(doc: TIEntity) => {
  if (!doc) {
    return null;
  }
  const col = doc.collection;
  let ent: T;
  switch (col) {
    case 'users':
      ent = new User(doc as IUser) as T;
      break;
    case 'user_profiles':
      ent = new UserProfile(doc as IUserProfile) as T;
      break;
    case 'user_accounts':
      ent = new UserAccount(doc as IUserAccount) as T;
      break;
    case 'tags':
        ent = new Tag(doc as ITag) as T;
        break;
    case 'tag_icons':
      ent = new TagIcon(doc as ITagIcon) as T;
      break;
    case 'orgs':
      ent = new Org(doc as IOrg) as T;
      break;
    case 'org_profiles':
      ent = new OrgProfile(doc as IOrgProfile) as T;
      break;
    case 'org_accounts':
      ent = new OrgAccount(doc as IOrgAccount) as T;
      break;
    case 'org_users':
      ent = new OrgUser(doc as IOrgUser) as T;
      break;
    // case 'communities':
    //   ent = new Community(doc as ICommunity) as T;
    //   break;
    // case 'community_profiles':
    //   ent = new CommunityProfile(doc as ICommunityProfile) as T;
    //   break;
    // case 'community_accounts':
    //   ent = new CommunityAccount(doc as ICommunityAccount) as T;
    //   break;
    // case 'community_members':
    //   ent = new CommunityMember(doc as ICommunityMember) as T;
    //   break;
    case 'sessions':
      ent = new Session(doc as ISession) as T;
      break;
    // case 'conversations':
    //   ent = new Conversation(doc as IConversation) as T;
    //   break;
    // case 'messages':
    //   ent = new Message(doc as IMessage) as T;
    //   break;
    // case 'needs':
    //   ent = new Need(doc as INeed) as T;
    //   break;
    // case 'resources':
    //   ent = new Resource(doc as IResource) as T;
    //   break;
    default:
      throw new Error(`models.helpers.fromDoc() missing case for ${col}`);
  }
  return ent;
};


export const getEntityDisplayType = (collection: TCollection) => {
  switch(collection) {
    case 'user_profiles':
      return 'user';
    case 'users':
      return 'user';
    case 'user_accounts':
      return 'users';
    case 'orgs':
      return 'organization';
    case 'org_profiles':
      return 'organization';
    case 'org_accounts':
      return 'org';
    // case 'communities':
    //   return 'community';
    // case 'community_profiles':
    //   return 'community';
    // case 'community_accounts':
    //   return 'community'
    // case 'needs':
    //   return 'need';
    // case 'resources':
    //   return 'resource';
    // case 'comments':
    //   return 'comment';
    // case 'communities':
    //   return 'community';
    default:
      console.error('Unsupported entity type', collection);
      return null;
  }
}

export const getEntityRoute = (collection: TCollection) => {
  switch(collection) {
    case 'user_profiles':
      return 'user';
    case 'users':
      return 'user';
    case 'user_accounts':
      return 'user';
    case 'orgs':
      return 'org';
    case 'org_profiles':
      return 'org';
    case 'org_accounts':
      return 'org';
    // case 'needs':
    //   return 'need';
    // case 'resources':
    //   return 'resource';
    // case 'comments':
    //   return 'comment';
    // case 'communities':
    //   return 'community';
    default:
      console.error('Unsupported entity type', collection);
      return null;
  }
}

export const getEntityDisplayName = (entity: Entity, full = false) => {
  const eType = getEntityType(entity.uid);
  switch (eType) {
    case 'user':
      return full ? (entity as User).firstName + ' ' + (entity as User).lastName : (entity as User).firstName;
    case 'org':
      return (entity as Org).name;
    // case 'community':
    //   return (entity as Community).name;
    default:
      console.error(`getEntityDisplayName unsupported entity type: ${entity.collection}`);
      return null;
  }
}

export type TBaseEntity = 'orgs' | 'users' | 'needs' | 'resources' | 'communities' | 'messages' | 'notifications' | 'feed' | 'deb' | 'conversations' | 'tags';

export const getEntityType = (uid: string) => {
  const prefix = uid.slice(0, 3);
  switch (prefix) {
    case 'usr':
      return 'user';
    case 'org':
      return 'org';
    case 'ned':
      return 'need';
    case 'res':
      return 'resource';
    case 'tags':
      return 'tag';
    case 'com':
      return 'community';
    default:
      console.error(`getEntityType not implemented for ${prefix}`);
      return null;
  }
}

export const getEntityCollection = (uid: string): TCollection => {
  const prefix = uid.slice(0, 3);
  switch (prefix) {
    case 'usr':
      return 'users';
    case 'tag':
      return 'tags';
    case 'org':
      return 'orgs';
    // case 'ned':
    //   return 'needs';
    // case 'res':
    //   return 'resources';
    // case 'cnv':
    //   return 'conversations';
    // case 'com':
    //   return 'communities';
    default:
      console.error(`getEntityCollection not implemented for ${prefix} -- ${uid}`);
      return null;
  }
}

export const getStorageDir = (uid: string): string => {
  const col = getEntityCollection(uid);
  return `beta/${col}/${uid}`
}

export const collectionPrefixMap: {[k in TBaseEntity]: string} = {
  users: 'usr',
  orgs: 'org',
  needs: 'ned',
  resources: 'res',
  communities: 'com',
  messages: 'msg',
  notifications: 'not',
  feed: 'fed',
  deb: 'deb',
  conversations: 'cnv',
  tags: 'tag',
};


export const generateUid = (col: TBaseEntity, fixedId: string = null) => {
  const makeid = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsLength = characters.length;
    for ( let i = 0; i < 15; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
  }
  if (!collectionPrefixMap[col]) {
    throw new Error(`generateUid not implemented for collection ${col}`);
  }
  const uid = `${collectionPrefixMap[col]}${fixedId ? fixedId : makeid()}`;
  return uid;
}

export const constructFilePath = (collection: TAvatarPathCollection, uid: string, folder: 'avatars' | 'files', fileName: string) => {
  if (!collection) throw Error(`constructFilePath error: no collection provided`);
  if (!uid) throw Error(`constructFilePath error: no uid provided`);
  if (!fileName) throw Error(`constructFilePath error: no file name provided`)
  const split = fileName.split('.').map(p => p.trim());
  let fname: string = null;
  let ext: string = null;
  if (split.length === 1) {
    if (folder === 'avatars') {
      fname = fileName;
      ext = 'jpg';
    }
    else if (folder === 'files') throw Error(`No file extension in fileName ${fileName}`);
    else throw Error(`Unkown folder: ${folder}`);
  } else if (split.length > 1) {
    ext = split.pop();
    fname = split.join('.');
  }
  // fname checks
  ['.','/','*','<','>','\\'].forEach(c => {
    if (fname.includes(c)) throw Error(`Invalid file name: cannot contain "${c}"'`);
  });
  // ext checks
  if (folder === 'avatars') {
    if (!['jpg','jpeg','png','gif'].includes(ext)) throw Error(`Invalid avatar file type: ${ext}`);
  }
  return `beta/${collection}/${uid}/${folder}/${fname}.${ext}`;
}
