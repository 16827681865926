import { Timestamp } from '@firebase/firestore';
import { StorageReference } from '@firebase/storage';
import { TCollection, Serializable } from './common.model';
export interface IAvatarMetadata {
  name: string;
  uid: string;
  collection: string;
}


export declare type HitAttributeHighlightResult = {
  value: string;
  matchLevel: 'none' | 'partial' | 'full';
  matchedWords: string[];
  fullyHighlighted?: boolean;
};
export declare type HitHighlightResult = {
    [attribute: string]: HitAttributeHighlightResult | HitAttributeHighlightResult[] | HitHighlightResult[] | HitHighlightResult;
};
export declare type HitAttributeSnippetResult = Pick<HitAttributeHighlightResult, 'value' | 'matchLevel'>;
export declare type HitSnippetResult = {
    [attribute: string]: HitAttributeSnippetResult[] | HitSnippetResult[] | HitAttributeSnippetResult | HitSnippetResult;
};
export declare type GeoLoc = {
    lat: number;
    lng: number;
};
export declare type AlgoliaHit = {
  [attribute: string]: any;
  objectID: string;
  _highlightResult?: HitHighlightResult;
  _snippetResult?: HitSnippetResult;
  _rankingInfo?: {
      promoted: boolean;
      nbTypos: number;
      firstMatchedWord: number;
      proximityDistance?: number;
      geoDistance: number;
      geoPrecision?: number;
      nbExactWords: number;
      words: number;
      filters: number;
      userScore: number;
      matchedGeoLocation?: {
          lat: number;
          lng: number;
          distance: number;
      };
  };
  _distinctSeqID?: number;
  _geoLoc?: GeoLoc;
  _geoloc?: GeoLoc;
};
export declare type Hit = {
  __position: number;
  __queryID?: string;
} & AlgoliaHit;
  
export interface IEntity extends Hit {
  uid: string; // firestore ID
  objectID: string; // for algolia
  queryMetadata: any; // placeholder for when result is returned by Algolia
  collection: TCollection;
  avatar?: StorageReference | string; // placeholder
  avatarMetadata?: IAvatarMetadata; // placeholder
  avatarUrl?: string;
  thumbnail?: StorageReference | string; // placeholder
  thumbnailMetadata?: IAvatarMetadata; // placeholder
  thumbnailUrl?: string;
  banner?: StorageReference | string;
  bannerMetadata?: IAvatarMetadata;
  bannerUrl?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  modelVersion: number; // in case migration is needed
  canView: string[];
  seed: boolean; // dev
}

export enum avatarCollectionMap {
  users = 'users',
  user_profiles = 'users',
  orgs = 'orgs',
  org_profiles = 'orgs',
  needs = 'needs',
  resources = 'resources',
  need_recipients = 'need_recipients',
  conversations = 'conversations',
  messages = 'messages',
  communities = 'communities',
  community_profiles = 'communities'
}
export class Entity extends Serializable implements IEntity, Hit {
  public static currentModelVersion = 1.0;
  public static fixedProperties = [
    'uid',
    'seed',
    'objectID',
    'name',
    'creatorId',
    'creator',
    'collection',
    'modelVersion',
    'createdAt',
    'updatedAt',
    'queryMetadata',
    'canView',
    'avatarUrl',
    'thumbnailUrl',
    'bannerUrl',
  ];

  // Placeholders (should not ever be written to db)
  public static DeleteKeys = [
    'avatar',
    'avatarMetadata',
    'thumbnail',
    'thumbnailMetadata',
    'banner',
    'bannerMetadata',
    'queryMetadata',
    'tags$',
    '_highlightResult','_snippetResult','_rankingInfo','_distinctSeqID','__position','__queryID','_geoLoc'
  ];
  public uid: string;
  public objectID: string;
  public queryMetadata: any;
  public collection: TCollection;
  public avatar?: StorageReference | string = null;
  public avatarMetadata?: IAvatarMetadata;
  public avatarUrl?: string;
  public thumbnail?: StorageReference | string = null;
  public thumbnailMetadata?: IAvatarMetadata;
  public thumbnailUrl?: string;
  public banner?: StorageReference | string = null;
  public bannerMetadata?: IAvatarMetadata;
  public bannerUrl?: string;
  public canView: string[] = ['anyone','admins'];
  public createdAt = Timestamp.fromDate(new Date());
  public updatedAt = Timestamp.fromDate(new Date());
  public modelVersion = Entity.currentModelVersion;
  public seed = false;
  // algolia hit props
  public __position: number;
  public __queryID?: string;
  public _highlightResult?: HitHighlightResult;
  public _snippetResult?: HitSnippetResult;
  public _rankingInfo?: {
      promoted: boolean;
      nbTypos: number;
      firstMatchedWord: number;
      proximityDistance?: number;
      geoDistance: number;
      geoPrecision?: number;
      nbExactWords: number;
      words: number;
      filters: number;
      userScore: number;
      matchedGeoLocation?: {
          lat: number;
          lng: number;
          distance: number;
      };
  };
  public _distinctSeqID?: number;
  public _geoLoc?: GeoLoc; // algolia version stuff
  public _geoloc?: GeoLoc;

  constructor(obj: Partial<Entity> = {}) {
    super();
    const {
      uid = null,
      collection = null,
      avatar = null,
      avatarMetadata = null,
      avatarUrl = null,
      thumbnail = null,
      thumbnailMetadata = null,
      thumbnailUrl = null,
      banner = null,
      bannerMetadata = null,
      bannerUrl = null,
      canView = ['anyone','admins'],
      createdAt = Timestamp.fromDate(new Date()),
      updatedAt = Timestamp.fromDate(new Date()),
      modelVersion = Entity.currentModelVersion,
      seed = false,
      queryMetadata = null,
      _highlightResult = null,
      _snippetResult = null,
      _rankingInfo = null,
      _distinctSeqID = null,
      _geoLoc = null,
      _geoloc = null,
      __position = null,
      __queryID = null
    } = obj;
    this.uid = uid;
    this.objectID = uid;
    this.collection = collection;
    this.avatar = avatar;
    this.avatarMetadata = avatarMetadata;
    this.avatarUrl = avatarUrl;
    this.thumbnail = thumbnail;
    this.thumbnailMetadata = thumbnailMetadata;
    this.thumbnailUrl = thumbnailUrl;
    this.banner = banner;
    this.bannerMetadata = bannerMetadata;
    this.bannerUrl = bannerUrl;
    this.canView = canView;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.modelVersion = modelVersion;
    this.seed = seed;
    this.queryMetadata = queryMetadata;
    this._highlightResult = _highlightResult;
    this._snippetResult = _snippetResult;
    this._rankingInfo = _rankingInfo;
    this._distinctSeqID = _distinctSeqID;
    this._geoLoc = _geoLoc;
    this.__position = __position;
    this.__queryID = __queryID;
    this._geoloc = _geoloc;
    this.finalize();
  }

  public setId(uid: string) {
    this.uid = uid;
    this.objectID = uid;
  }

  // converts any values from undefined to null (recursive)
  public static undefinedToNull = (obj: Partial<Entity | IEntity>) => {
    Object.getOwnPropertyNames(obj).forEach(k => {
      if (obj[k] === undefined || obj[k] === null) {
        obj[k] = null;
      } else if ( typeof(obj[k]) === 'object') {
        obj[k] = Entity.undefinedToNull(obj[k]);
      }
    });
    return obj;
  }

  // Jsonifies classes
  public toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  // Converts any class values to appropriate Firestore values
  public toDB() {
    const ret = super.toDB() as IEntity;
    // override firestore values
    ['createdAt','updatedAt'].forEach(k => {
      ret[k] = this[k];
    });
    if (!ret.uid && !ret.seed) {
      throw new Error('no uid');
    }
    if (!ret.objectID) {
      ret.objectID = this.uid;
    }
    Entity.DeleteKeys.forEach(k => {
      delete ret[k];
    });
    return Entity.undefinedToNull(ret);
  }

  public extractProperties(obj: Partial<IEntity>, props: string[]) {
    for (const prop of props) {
      if (obj && prop in obj) {
        if (!!obj[prop]) {
          this[prop] = obj[prop];
        } else if (obj[prop] === false) {
          this[prop] = false;
        } else {
          if (!this[prop]) this[prop] = null;
        }
      }
    }
    this.finalize();
  }

  public static processValues(value: any) {
    if (value === undefined) {
      return null;
    } else if (value === false) {
      return false;
    } else if (value === null) {
      return null;
    } else if (value._seconds && value._nanoseconds) {
      // if algolia timestamp, convert to firestore timestamp
      return new Timestamp(value._seconds, value._nanoseconds);
    } else if (value.seconds && value.nanoseconds) {
      // if algolia timestamp, convert to firestore timestamp
      return new Timestamp(value.seconds, value.nanoseconds);
    } else if (value instanceof Array) {
      return value;
    } else if (typeof(value) === 'object') {
      // if subobject, recursively process sub-objects
      const ret = {};
      Object.keys(value).forEach(k => {
        ret[k] = Entity.processValues(value[k]);
      });
      return ret;
    } else {
      return value;
    }
  }

  public finalize() {
    Object.keys(this).forEach(k => {
      this[k] = Entity.processValues(this[k]);
    });
  }

}
