import { IEntity, Entity } from './entity.model';
import { User } from './user.model';
import { IContactInfo, IPhoneNumber, PhoneNumber } from './common.model';
import { faker } from '@faker-js/faker';
import { IGeoEntity, GeoEntity } from './geoEntity.model';
import { INotificationSettings } from './notifications.model';
// import { IStripeEntity, IPaymentMethods } from './stripe.model';

type TOrgCollection = 'orgs';
type TOrgProfileCollection = 'org_profiles';
type TOrgCommonCollection = TOrgCollection | TOrgProfileCollection;
type TOrgAccountCollection = 'org_accounts';
type TOrgUserCollection = 'org_users';
export type TCompanyType = '501c' | '501c-pending' | 'K-12' | 'College/University' | 'Government' | 'Hospice/Hospital' | 'Other';
export const CompanyTypes = ['501c','501c-pending','K-12','College/University','Government','Hospice/Hospital','Other'];
export const OrgRequiredProperties = [
  'name',
  'phoneNumber',
  'orgType',
];

interface IOrgCommon {
  // required
  name: string;
  phoneNumber: IPhoneNumber;
  contactInfo: IContactInfo;
  orgType: TCompanyType;
  // optional
  description: string;
  bio?: string;
  mission?: string;
  EIN?: string;
  website?: string;
  archived?: boolean,
  additonalDetails?: {
    [key: string]: string;
  };
  // computed
  // userId: string; // placeholder for merge with orgUsers
  // orgId: string; // placeholder for merge with orgUsers
  // role: number; // placeholder for merge with orgUsers
  // default
  collection: TOrgCommonCollection;
  /**
   * Inherited from IEntity
   *
   * uid: string;
   * objectID: string // for algolia
   * queryMetadata: string // placeholder for when results is returned by algolia
   * avatar: firebase.storeage.Reference // placeholder
   * avatarMetadata: IAvatarMetadata // placeholder
   * thumbnail: firebase.storage.Referece // placeholder
   * thumbnailMetadata: IAvatarMetadata // placeholder
   * createdAt: Timestamp
   * updatedAt: Timestamp
   * modelVersion: number // in case migration is needed
   * seed: boolean // dev
   */

  /**
  * Inherited from IGeoEntity
  *
  * geo: IGeo // for firestore geo queries
  * address: IAddress
  * _geoloc: IAlgoliaGeo // for algolia geo queries
  */
}

export interface IOrgProfile extends GeoEntity, IOrgCommon {
  collection: TOrgProfileCollection;
}
export class OrgProfile extends GeoEntity implements IOrgProfile {
  public static currentModelVersion = 1.2;
  public static Collection: TOrgProfileCollection = 'org_profiles';
  public static DefaultAvatar = 'assets/img/default_avatar.png';
  public collection = OrgProfile.Collection;
  public modelVersion = OrgProfile.currentModelVersion;

  public publicProperties: string[] = [
    'name',
    'description',
    'bio',
    'mission',
    'orgType',
    'additionalDetails',
    'EIN',
    'website',
    'contactInfo',
    'archived',
  ];
  // required
  public name: string;
  public phoneNumber: IPhoneNumber;
  public contactInfo: IContactInfo;
  public orgType: TCompanyType;
  // optional
  public description: string;
  public bio: string;
  public mission: string;
  public EIN: string;
  public website: string;
  public additionalDetails: { [key: string]: string };
  public archived: boolean;
  // placeholders
  // public orgId: string;
  // public userId: string;
  // public role: number;

  constructor(obj: Partial<OrgProfile>) {
    super(obj);
    if (obj && 'publicProperties' in obj) {
      this.publicProperties = obj['publicProperties'];
    }
    if (this.publicProperties.indexOf('address') > -1) {
      this.publicProperties.splice(this.publicProperties.indexOf('address')); // TODO: Hack remove
    }
    if (this.publicProperties.indexOf('geo') > -1) {
      this.publicProperties.splice(this.publicProperties.indexOf('geo')); // TODO: Hack remove
    }
    this.extractProperties(obj, Entity.fixedProperties.concat(this.publicProperties).concat(OrgRequiredProperties));
    this.collection = OrgProfile.Collection;
    this.modelVersion = OrgProfile.currentModelVersion;
  }

  public static fromCore(org: Org) {
    const data: Partial<IOrgProfile> = {};
    this.fixedProperties.forEach(k => {
      if (k in org) {
        data[k] = org[k];
      }
    });
    org.publicProperties.forEach(k => {
      data[k] = org[k];
    });
    if (org.address) data.address = org.address; // TODO: this is a hack, find a better way
    return new OrgProfile(data);
  }
}

export interface IOrg extends IGeoEntity, IOrgCommon {
  collection: TOrgCollection;
  publicProperties: string[];
}

export class Org extends GeoEntity implements IOrg {
  public static currentModelVersion = 1.1;
  public static Collection: TOrgCollection = 'orgs';
  public static DefaultAvatar = 'assets/img/default_avatar.png';
  public static RequiredProperties = OrgRequiredProperties;
  public collection = Org.Collection;
  public modelVersion = Org.currentModelVersion;
  public publicProperties: string[] = [
    'name',
    'description',
    'bio',
    'mission',
    'orgType',
    'website',
    'additionalDetails',
    'EIN',
    'contactInfo',
    'archived'
  ];
  // required
  public name: string;
  public phoneNumber: IPhoneNumber;
  public contactInfo: IContactInfo;
  public orgType: TCompanyType;
  // optional
  public description: string;
  public bio: string;
  public mission: string;
  public EIN: string;
  public archived: boolean;
  public additionalDetails: { [key: string]: string };
  public orgId: string;
  public website: string;
  public userId: string;
  public role: number;

  constructor(obj: Partial<IOrg> = {}) {
    super(obj);
    const {
      name,
      // address is inherited
      phoneNumber,
      contactInfo,
      orgType,
      description = null,
      bio = null,
      mission = null,
      EIN = null,
      publicProperties = this.publicProperties,
      userId = null,
      website = null,
      orgId = null,
      role = null,
    } = obj;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.contactInfo = contactInfo;
    this.orgType = orgType;
    this.description = description;
    this.bio = bio;
    this.mission = mission;
    this.EIN = EIN;
    this.website = website;
    if (publicProperties) this.publicProperties = publicProperties;
    this.userId = userId;
    this.orgId = orgId;
    this.role = role;
  }

  public static seedOrg(user: User, orgData: Partial<Org> = {}) {
    console.log('seeding org');
    const uid = null;
    const name = faker.company.companyName();
    const phoneNumber: IPhoneNumber = {
      phoneNumber: PhoneNumber.seedPhoneNumber().phoneNumber,
      phoneType: 'work'
    };
    const contactName = user.displayName ? user.displayName : user.firstName + ' ' + user.lastName;
    const contactInfo: IContactInfo = {
      [contactName]: {
        name: contactName,
        email: user.email,
        phoneNumber,
        info: 'Available 9-5'
      }
    };
    const orgType: TCompanyType = '501c';
    const description = faker.lorem.paragraph(4);
    const bio = faker.lorem.paragraphs(3);
    const mission = faker.lorem.sentence();
    const EIN = faker.random.uuid();
    return new this({
      // required
      name,
      phoneNumber,
      contactInfo,
      orgType,
      // optional
      description,
      bio,
      mission,
      EIN,
      // default
      uid,
      ...orgData,
      seed: true,
    });

  }

  public generateProfile() {
    return OrgProfile.fromCore(this);
  }

}

export interface IOrgAccount extends IEntity {
  collection: TOrgAccountCollection;
  stripeCustomerId: string;
  stripeConnectId: string;
  algoliaApiKey: string;
  notificationSettings: INotificationSettings;
  name: string;
  gcmTokens: string[];
  defaultPaymentMethodId: string; // firestore uid of default payment method
}

export class OrgAccount extends Entity implements IOrgAccount {
  public static currentModelVersion = 1.2;
  public static Collection: TOrgAccountCollection = 'org_accounts';
  public collection = OrgAccount.Collection;
  public modelVersion = OrgAccount.currentModelVersion;

  public stripeCustomerId: string;
  public stripeConnectId: string;
  public algoliaApiKey: string;
  public notificationSettings: INotificationSettings;
  public name: string;
  public gcmTokens: string[];
  public defaultPaymentMethodId: string;
  // public paymentMethods: IPaymentMethods;

  constructor(obj: Partial<IOrgAccount>) {
    super(obj);
    const {
      stripeCustomerId,
      stripeConnectId,
      algoliaApiKey,
      notificationSettings,
      name,
      gcmTokens = null,
      defaultPaymentMethodId = null,
      // paymentMethods = null
    } = obj;
    if (stripeCustomerId) this.stripeCustomerId = stripeCustomerId;
    if (stripeConnectId) this.stripeConnectId = stripeConnectId;
    if (algoliaApiKey) this.algoliaApiKey = algoliaApiKey;
    if (notificationSettings) this.notificationSettings = notificationSettings;
    if (name) this.name = name;
    this.collection = OrgAccount.Collection;
    this.modelVersion = OrgAccount.currentModelVersion;
    this.gcmTokens = gcmTokens;
    this.defaultPaymentMethodId = defaultPaymentMethodId;
    // this.paymentMethods = paymentMethods;
  }
}

/*
Org user roles:
1 - creator/owner
2 - admin
10 - member
20 - subscriber
*/

export interface IOrgUser extends IEntity {
  collection: TOrgUserCollection;
  // user: Partial<IUser>;
  userId: string;
  // org: Partial<IOrg>;
  orgId: string;
  role: string;
}

export class OrgUser extends Entity implements IOrgUser {
  public static currentModelVersion = 2.0;
  public static Collection: TOrgUserCollection = 'org_users';
  public collection = OrgUser.Collection;
  public modelVersion = OrgUser.currentModelVersion;

  public userId: string;
  public orgId: string;
  public role: string;
  public seed = false;

  constructor(obj: Partial<IOrgUser>) {
    super(obj);
    this.userId = obj.userId;
    this.orgId = obj.orgId;
    this.role = obj.role;
    this.uid = `${obj.orgId}-${obj.userId}`;
    'seed' in obj ? this.seed = obj.seed : this.seed = false;
  }
}

