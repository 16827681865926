import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, iif } from 'rxjs';
import { User, UserAccount, UserProfile } from '@models/user.model';
import { User as fbUser, getAuth, signInWithEmailAndPassword, Auth, sendPasswordResetEmail } from 'firebase/auth';
import { authState } from 'rxfire/auth';
import { FunctionsService } from './functions.service';
import { map, distinctUntilChanged, switchMap, skipWhile, shareReplay, mergeMap, tap } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import { FirebaseApp } from 'firebase/app';
import { CreateUserParams } from '@models/functions/users.functions.model';

export interface ICurrentUser {
  isAuthenticated: boolean;
  isGuest: boolean;
  isInitialized: boolean;
  fbUser: fbUser;
  data?: User;
  profile?: UserProfile;
  account?: UserAccount;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser$: Observable<ICurrentUser>;
  public currentUser: ICurrentUser;
  public fbUser$: Observable<fbUser | null>;
  firebaseAuth: Auth;
  isAuthenticated: boolean;

  constructor(
    private fun: FunctionsService,
    private fs: FirestoreService,
  ) {
  }

  /**
   * Resolver for app module
   * 
   * @returns CurrentUser
   */
  public appInit(app: FirebaseApp) {
    this.firebaseAuth = getAuth(app);
    return new Promise<ICurrentUser>((resolve, reject) => {
      this.fbUser$ = authState(this.firebaseAuth);
      const isSignedIn$ = this.fbUser$.pipe(
        distinctUntilChanged((a, b) => a.uid === b.uid),
        switchMap(auth => {
          if (!auth.uid) {
            return combineLatest([of(auth), of(null), of(null), of(null)]);
          } else {
            return combineLatest([
              of(auth),
              this.fs.docToEntity$<User>(`users/${auth.uid}`, {avatar: true, thumbnail: true, banner: true}),
              this.fs.docToEntity$<UserProfile>(`user_profiles/${auth.uid}`),
              this.fs.docToEntity$<UserAccount>(`user_accounts/${auth.uid}`),
            ])
          }
        }),
        map(([firebaseUser, data, profile, account]) => {
          return {
            isAuthenticated: !!firebaseUser,
            isGuest: !firebaseUser,
            isInitialized: true,
            fbUser: firebaseUser,
            data,
            profile,
            account,
          } as ICurrentUser;
        }),
        shareReplay()
      );
      const isNotSignedIn$ = of({
        isAuthenticated: false,
        isGuest: true,
        isInitialized: true,
        fbUser: null,
        data: null,
        profile: null,
        account: null,
        orgs: null
      } as ICurrentUser)
  
      this.currentUser$ = this.fbUser$.pipe(
        mergeMap(firebaseAuthState => iif(
          () => firebaseAuthState && !!firebaseAuthState.uid,
          isSignedIn$,
          isNotSignedIn$
        ))
      );
      this.currentUser$.pipe(skipWhile(x => !x.isInitialized)).subscribe(cu => {
        this.currentUser = cu;
        console.log('cu', cu);
        resolve(cu); 
      });
    });
  }


  public async isEmailAvailable(email: string) {
    console.log('checking if email is available', email);
    if (!email) {
      return false;
    }
    const res = await this.fun.isEmailAvailable().execute({email});
    console.log('email available?', res.available);
    return res.available;
  }


  public emailSignup = async (email: string, password: string, userData: User, generatePassword = false) => {
    console.log('in email signup', userData);
    try {
      const params: CreateUserParams = {
        email,
        password,
        userData: userData.toJson(),
        generatePassword
      };
      console.log('sending function request', params);
      const res = await this.fun.createUser().execute(params);
      console.log('emailSignup response', res);
      const uid = res.uid;
      signInWithEmailAndPassword(getAuth(), email, password);
      console.log('signed in');
      return res;
    } catch (err) {
      console.error('error', err);
      throw new Error(`${err}`);
    }
  }

  async logout() {
    await this.firebaseAuth.signOut();
  }

  async onResetPassword(email: string) {
    await sendPasswordResetEmail(this.firebaseAuth, email);
    return;
  }

}
