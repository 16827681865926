import { IGeoEntity, GeoEntity } from './geoEntity.model';
import { Entity } from './entity.model';
export type TSessionCollection = 'sessions';
export interface ISessionContext {
  collection: 'user' | 'org';
  uid: string;
  activeCommunityId?: string;
}

export type TPlatform = 
'android'
| 'cordova'
| 'ios'
| 'ipad'
| 'iphone'
| 'phablet'
| 'tablet'
| 'electron'
| 'pwa'
| 'mobile'
| 'mobileweb'
| 'desktop'
| 'hybrid'
;

export interface ISessionSettings {
  showHints: true;
}

export interface ISession extends IGeoEntity {
  userId: string;
  scratch: any;
  platform: TPlatform[];
  routerHistory: string[];
  context: ISessionContext;
  settings: ISessionSettings;
  // mobile: boolean;
}

export const SessionProperties = ['userId', 'scratch', 'platform', 'routerHistory', 'canView', 'modelVersion', 'context', 'settings'];

export class Session extends GeoEntity implements ISession {
  public static currentModelVersion = 1.0;
  public static Collection: TSessionCollection = 'sessions';
  public collection = Session.Collection;
  public modelVersion = Session.currentModelVersion;

  public userId: string;
  public scratch: any;
  public platform: TPlatform[] = null;
  public routerHistory: string[] = [];
  public searchHistory: string[];
  public context: ISessionContext = {collection: 'user', uid: null};
  public settings: ISessionSettings = null;

  constructor(obj: Partial<ISession> = {}) {
    super(obj);
    this.modelVersion = Session.currentModelVersion;
    this.collection = Session.Collection;
    this.extractProperties(obj, Entity.fixedProperties.concat(SessionProperties));
    if (!this.context.uid) {
      this.context.uid = this.userId;
      this.context.collection = 'user';
    }
    if (!this.settings) this.settings = {showHints: true};
  }

  public partialUpdate(obj: Partial<ISession>) {
    this.extractProperties(obj, Entity.fixedProperties.concat(SessionProperties));
  }

}
