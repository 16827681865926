import { Injectable } from '@angular/core';
import { Observable, combineLatest, Subscription, from, of, BehaviorSubject, throwError, iif } from 'rxjs';
import { map, skipWhile, tap, distinctUntilChanged, distinctUntilKeyChanged, mergeMap, shareReplay, switchMap, take, startWith } from 'rxjs/operators';
import { AlgoliaService } from './algolia.service';
import { AuthService } from './auth.service';
import { User, UserAccount, UserProfile } from '@models/user.model';
import { Session } from '@models/session.model';
import { Address } from '@models/common.model';
import { getEntityType } from '@models/models.helpers';
import { collection, doc, Firestore, getFirestore, query, setDoc, Timestamp, where } from 'firebase/firestore';
import { FirestoreService } from './firestore.service';
import { AppService } from './app.service';
import { Org, OrgAccount, OrgProfile } from '@models/org.model';
import { FirebaseApp } from 'firebase/app';
import { collectionData } from 'rxfire/firestore';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
export interface IContextData {
  data: User | Org;
  profile: UserProfile | OrgProfile;
  account: UserAccount | OrgAccount;
  // notifications: AppNotification[];
}
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public sessionData$: Observable<Session>;
  public sessionRouterSub: Subscription;
  public contextData$: Observable<IContextData>;
  public contextDataDistinct$: Observable<IContextData>;
  // public screenWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
  contextData: IContextData;
  contextOrgs$: Observable<Org[]>;
  
  public isNative = false;
  public isSmall = false;
  public mobileView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public mobileView = false;
  public avatarUrl$: Observable<string>;
  public thumbnailUrl$: Observable<string>;
  public bannerUrl$: Observable<string>;
  
  public darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  firestore: Firestore;

  constructor(
    private as: AlgoliaService,
    private authService: AuthService,
    private fs: FirestoreService,
    public platform: Platform
  ) {
    this.isNative = Capacitor.isNativePlatform()
    platform.ready().then(() => {
      this.updatePlatformSize();
      platform.resize.subscribe(() => {
        this.updatePlatformSize();
      })
    });
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark', this.darkMode);
  }

  updatePlatformSize() {
    this.isSmall = this.platform.width() <= 768;
    this.mobileView$.next(this.isSmall || this.isNative);
    this.mobileView = this.mobileView$.getValue();
    console.log(`viewSize changed -- width: ${this.platform.width()} height: ${this.platform.height()} native: ${Capacitor.isNativePlatform()} mobileView: ${this.mobileView$.getValue()}`);

  }

  appInit(app: FirebaseApp) {
    this.firestore = getFirestore(app);
    this.sessionData$ = this.authService.currentUser$.pipe(
      skipWhile(cu => !cu.isInitialized),
      switchMap(cu => {
        if (cu && cu.isAuthenticated) {
          return this.fs.docToEntity$<Session>(`sessions/${cu.fbUser.uid}`);
        } else {
          console.log('guest user', cu);
          return of(null);
        }
      })
    );
    this.getContextData();
    this.sessionData$.subscribe(sd => {
      console.log('session data', sd);
    });
    this.contextData$.subscribe(cd => {
      console.log('context data', cd);
    })

    return new Promise((resolve, reject) => {
      combineLatest([this.sessionData$, this.authService.currentUser$]).pipe(
        skipWhile(([sd, cu]) => !cu.isInitialized),
      ).subscribe(([sd, cu]) => {
        if (cu.isAuthenticated && sd) {
          resolve(sd);
        } else if (!cu.isAuthenticated) {
          resolve(null);
        }
      })
    })
  }


  async setContext(entity: User | Org) {
    const fbCurrentUser = this.authService.currentUser.fbUser;
    console.log('setting context', entity);
    if (!fbCurrentUser || (this.contextData && this.contextData.data && this.contextData.data.uid === entity.uid)) {
      return;
    }
    const ref = doc(this.firestore, `sessions/${fbCurrentUser.uid}`);
    return await setDoc(ref, {
      updatedAt: Timestamp.now(),
      context: {
        uid: entity.uid,
        collection: entity.collection === 'users' ? 'user' : 'org'
      }
    }, {merge: true});
  }

  /**
   * If the context changes, this will updates the contextData .
   */
  private getContextData = () => {
    const contextDataStream = this.sessionData$.pipe(
      skipWhile(session => !(session && session.context)),
      // only emit if the context has changed (either user changed to org, vice versa, or user logged into different account)
      map(session => session.context),
      // distinctUntilChanged((a, b) => {
      //   return a.uid === b.uid && a.collection === b.collection
      // }),
      tap(c => console.log('session context changed', c)),
      // should only get here when the context has changed, get account so we have the API key for Algolia querying
      mergeMap(context => {
        if (context.collection === 'user') {
          return this.fs.docToEntity$<UserAccount>(`user_accounts/${context.uid}`).pipe(map(account => ({context, account})))
        } else if (context.collection === 'org') {
          if (!context.uid) console.error('no context uid', context);
          if (!context.uid) return throwError('context is org but no uid is found');
          return this.fs.docToEntity$<OrgAccount>(`org_accounts/${context.uid}`).pipe(map(account => ({context, account})))
        } else {
          return of(null);
        }
      }),
      // we don't care about changes to the account, so only emit if the account's UID has changed.
      // distinctUntilChanged((a, b) => a.account.uid === b.account.uid),
      // now we have the account so update the context data with new API key
      mergeMap(({context, account}) => {
        const algoliaClient = this.as.getClient(account.algoliaApiKey);
        if (context.collection === 'user') {
          return combineLatest([
            // if context is user, pull data, profile from auth service
            this.authService.currentUser$.pipe(map(cu => cu.data)),
            this.authService.currentUser$.pipe(map(cu => cu.profile)),
            of(account as UserAccount), // we already have the account
            // collectionData(query(collection(this.firestore, 'notifications'), where('canView','array-contains',context.uid))).pipe(
            //   map(hits => hits.map(hit => new AppNotification(hit)))
            // )
          ])
        } else if (context.collection === 'org') {
          return combineLatest([
            // if context is org, pull data and profile from firestore
            this.fs.docToEntity$<Org>(`orgs/${context.uid}`, {avatar: true, thumbnail: true, banner: true}),
            this.fs.docToEntity$<OrgProfile>(`org_profiles/${context.uid}`),
            of(account), // we already have the account,
            // collectionData(query(collection(this.afs, 'notifications'), where('canView','array-contains',context.uid))).pipe(
            //   map(hits => hits.map(hit => new AppNotification(hit)))
            // )
          ])
        } else {
          return null;
        }
      }),
      map(([data, profile, account]) => ({
        data,
        profile,
        account,
        // notifications
      }) as IContextData),
      shareReplay(1),
    );
    this.contextData$ = this.authService.currentUser$.pipe(
      mergeMap(cu => iif(() => cu.isAuthenticated, contextDataStream, of(null))),
    );
    this.avatarUrl$ = this.contextData$.pipe(
      map(cd => cd && cd.data && cd.data.avatarUrl ? cd.data.avatarUrl : 'assets/img/default_avatar.png')
    );
    this.thumbnailUrl$ = this.contextData$.pipe(
      map(cd => cd && cd.data && cd.data.thumbnailUrl ? cd.data.thumbnailUrl : 'assets/img/default_avatar.png')
    );
    this.bannerUrl$ = this.contextData$.pipe(
      map(cd => cd && cd.data && cd.data.bannerUrl ? cd.data.bannerUrl : 'assets/img/default_banner.png')
    );
    this.contextData$.subscribe(cd => {
      // console.log('context data changed', cd);
      this.contextData = cd;
    });
    this.contextDataDistinct$ = this.contextData$.pipe(
      distinctUntilChanged((a, b) => a.data.uid === b.data.uid),
    );
  }

  public awaitContextData() {
    return this.contextData$.pipe(
      skipWhile(cd => !cd.data || !cd.data.uid),
      take(1)
    ).toPromise();
  }

  public async getContext() {
    return this.contextData$.pipe(
      skipWhile(cd => !cd?.data?.uid),
      take(1),
      map(cd => {return {uid: cd.data.uid, type: getEntityType(cd.data.uid)}})
    ).toPromise();
  }
}
