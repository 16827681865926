export type TCollection =
'users' | 'user_profiles' | 'user_accounts' | 'user_metrics'
| 'orgs' | 'org_users' | 'org_profiles' | 'org_accounts'
// | 'needs' | 'need_accounts' | 'need_recipients' | 'sponsored_needs' | 'commitments'
// | 'resources' | 'resource_accounts'
// | 'communities' | 'community_profiles' | 'community_accounts' | 'community_members'
| 'tags' | 'tag_accounts' | 'tag_icons'
// | 'conversations' | 'messages'
| 'sessions'
// | 'metrics'
// | 'comments' | 'commentVotes'
// | 'bookmarks' | 'bookmark_groups' | 'categories'
;

export type TAvatarCollection = 
'users' | 'user_profiles'
| 'orgs' | 'org_profiles'
// | 'communities' | 'community_profiles'
// | 'needs'
// | 'resources'
// | 'need_recipients'
;

export type TAvatarPathCollection = 'users' | 'orgs' | 'communities' | 'needs' | 'resources' | 'need_recipients';
export const AvatarCollection = ['users', 'user_profiles', 'orgs', 'org_profiles', 'communities', 'community_profiles', 'needs', 'resources', 'need_recipients'];

export type TGender = 'Male' | 'Female' | 'Other' | 'Prefer Not to Say';
export type TRace = 'American Indian or Alaska Native' | 'Asian' | 'Black or African American' | 'Hispanic or Latino' | 'Native Hawaiian or Other Pacific Islander' | 'White' | 'Prefer Not to Say';

export const AVATAR_SIZE = '250';
export const THUMBNAIL_SIZE = '64';

export type PhoneNumberType = 'mobile' | 'work' | 'home';
import { addDays  } from 'date-fns';
import { ICoordinates } from './geo.model';


export const toJson = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};
export const toDB = (value: any) => {
  if (value === undefined) {
    return null;
  } else if (value === false) {
    return false;
  } else if (value === null) {
    return null;
  } else if (value instanceof Array) {
    return value;
  } else if (value instanceof(Object)) {
    if (value.toDB) {
      return value.toDB();
    } else {
      const ret = {};
      Object.getOwnPropertyNames(value).forEach(k => {
        ret[k] = toDB(value[k]);
      });
      return ret;
    }
  } else {
    return value;
  }
}
export class Serializable {
  public toJson() {
    const ret: any = {};
    Object.getOwnPropertyNames(this).forEach(key => {
      ret[key] = toJson(this[key]);
    });
    return ret;
  }

  public toDB() {
    const ret = {};
    Object.keys(this).forEach(k => {
      ret[k] = toDB(this[k]);
    });
    return ret;
  }
}

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { Timestamp } from '@firebase/firestore';
export interface IPhoneNumber {
  phoneNumber: string;
  e164?: string;
  primary?: boolean;
  phoneType?: PhoneNumberType;
  label?: string;
}

export class PhoneNumber extends Serializable implements IPhoneNumber {
  public primary?: boolean;
  public phoneType?: PhoneNumberType;
  public label?: string;
  private _phoneNumber: string;
  private _e164: string;
  get phoneNumber() {
    return this._phoneNumber;
  }
  set phoneNumber(phoneNumber: string) {
    this._phoneNumber = phoneNumber;
    if (phoneNumber && phoneNumber.length > 9) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      try {
        const standardized = phoneNumberUtil.parseAndKeepRawInput(
          phoneNumber, 'US'
        );
        const validNumber = phoneNumberUtil.isValidNumber(standardized);
        if (validNumber) {
          const formatted = phoneNumberUtil.format(standardized, PhoneNumberFormat.E164);
          this._e164 = formatted;
        }
      } catch (err) {
        console.error(`Unable to format phone number ${phoneNumber}`);
        this._e164 = null;
      }
    }
  }
  get e164() {
    return this._e164;
  }

  constructor(obj: Partial<IPhoneNumber>) {
    super();
    const {phoneNumber, phoneType = null, label = null, primary = false} = obj;
    this.phoneNumber = phoneNumber;
    this.phoneType = phoneType;
    this.primary = primary;
    this.label = label;
  }

  public toDB() {
    const ret: IPhoneNumber = {
      phoneNumber: this._phoneNumber,
      e164: this._e164,
    };
    ['label','phoneType','primary'].forEach(k => {
      if (this[k]) ret[k] = this[k];
    });
    return ret;
  }

  public static seedPhoneNumber() {
    return new this({
      phoneNumber: '8338626489',
    });
  }

}

export interface IContactInfo {
  [label: string]: {
    email?: string;
    phoneNumber?: IPhoneNumber;
    address?: IAddress;
    name?: string;
    info?: string;
    uid?: string;
  };
}

export interface IDate {
  startTS: Timestamp;
  endTS: Timestamp;
}

export class DateRange implements IDate {
  public startTS: Timestamp = null;
  public endTS: Timestamp = null;

  constructor(startTS?: Date | Timestamp, endTS?: Date | Timestamp) {
    if (startTS instanceof Date) {
      this.startTS = Timestamp.fromDate(startTS);
    } else if (startTS instanceof Timestamp) {
      this.startTS = startTS;
    }
    if (endTS instanceof Date) {
      this.endTS = Timestamp.fromDate(endTS);
    } else if (endTS instanceof Timestamp) {
      this.endTS = endTS;
    }
  }

  public static fromDates(startTS?: Date, endTS?: Date) {
    const ret = new DateRange();
    if (startTS) {
      ret.startTS = Timestamp.fromDate(startTS);
    }
    if (endTS) {
      ret.endTS = Timestamp.fromDate(endTS);
    }
    return ret;
  }

  public static seedDateRange(startTS?: Date | Timestamp, endTS?: Date | Timestamp) {
    const ret = new DateRange(startTS, endTS);
    if (!ret.startTS) {
      ret.startTS = Timestamp.fromDate(new Date());
    }
    if (!ret.endTS) {
      const dt = new Date();
      addDays(dt, 30);
      ret.endTS = Timestamp.fromDate(dt);
    }
    return ret;
  }
}


export interface IPlace {
  formatted_address: string;
  address_components?: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  verified?: boolean;
  lat?: number;
  lng?: number;
  place_id?: string;
  id?: string;
  types?: string[];
  vicinity?: string;
}

export class Place extends Serializable implements IPlace {
  formatted_address: string;
  address_components?: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  verified? = false;
  lat?: number;
  lng?: number;
  place_id?: string;
  types?: string[];

  constructor(obj: google.maps.places.PlaceResult | google.maps.GeocoderResult, verified = false) {
    super();
    const lat = obj.geometry.location.lat();
    const lng = obj.geometry.location.lng();
    const {
      formatted_address,
      address_components = [],
      place_id = null,
      types = [],
    } = obj;
    this.verified = verified;
    this.formatted_address = formatted_address;
    this.address_components = address_components;
    this.place_id = place_id;
    this.types = types;
    this.lat = lat;
    this.lng = lng;
  }
}

export interface IAddress {
  label?: string;
  line1: string;
  line2: string;
  formatted_address?: string;
  place: IPlace;
  neighborhood?: string;
  city?: string;
  county?: string;
  state?: string;
  country?: string;
  zip?: string;
}

export class Address extends Serializable implements IAddress {
  public label: string = null;
  public line2: string = null;
  public neighborhood: string = null;
  public city: string = null;
  public county: string = null;
  public state: string = null;
  public country: string = null;
  public zip: string = null;
  private _place: IPlace = null;
  get place() {
    return this._place;
  }
  set place(place: IPlace) {
    this._place = place;
    this.setAddressComponents();
  }
  get lat() {
    return this?.place?.lat || null;
  }
  get lng() {
    return this?.place?.lng || null;
  }
  get coords(): ICoordinates {
    return {
      lat: this.lat,
      lng: this.lng
    };
  }
  get state_short() {
    return this.getAddressComponent('administrative_area_level_1', 'short');
  }
  get country_short() {
    return this.getAddressComponent('country', 'short');
  }
  get street_number() {
    return this.getAddressComponent('street_number', 'long');
  }
  get route() {
    return this.getAddressComponent('route', 'long');
  }
  get formatted_address() {
    return this.place.formatted_address;
  }
  get line1() {
    if (!this.street_number) {
      return null;
    } else if (this.line2) {
      return `${this.street_number} ${this.route} ${this.line2}`;
    } else if (!this.route) {
      return `${this.street_number}`;
    } else {
      return `${this.street_number} ${this.route}`;
    }
  }

  constructor(
    place: IPlace,
    line2: string = null,
    label: string = null,
  ) {
    super();
    this.place = place;
    this.line2 = line2;
    this.label = label;
  }

  getAddressComponent(k: string, name_type: 'short' | 'long' = 'long') {
    if (!this.place || !this.place.address_components) return null;
    for (const c of this.place.address_components) {
      if (c.types.includes(k)) {
        return name_type === 'long' ? c.long_name : c.short_name;
      }
    }
    return null;
  }

  private setAddressComponents() {
    const place = this.place;
    if (place && place.address_components) {
      place.address_components.forEach(c => {
        if (c.types.includes('locality')) this.city = c.long_name;
        else if (c.types.includes('administrative_area_level_1')) {
          this.state = c.long_name;
        }
        else if (c.types.includes('country')) {
          this.country = c.long_name;
        }
        else if (c.types.includes('administrative_area_level_2')) this.county = c.long_name;
        else if (c.types.includes('neighborhood')) this.neighborhood = c.long_name;
        else if (c.types.includes('postal_code')) this.zip = c.long_name;
      });

    }
  }

  public static fromGoogle(res: google.maps.GeocoderResult | google.maps.places.PlaceResult, line2: string = null) {
    const place = new Place(res).toJson() as IPlace;
    return new this(place, line2);
  }

  public toDB() {
    if (!this.place) {
      return null;
    }
    const ret: IAddress = {
      label: this.label,
      line1: this.line1,
      line2: this.line2,
      place: this.place,
      neighborhood: this.neighborhood,
      city: this.city,
      county: this.county,
      state: this.state,
      country: this.country,
      zip: this.zip
    }
    return ret;
  }
}
