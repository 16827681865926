import { IEntity, Entity } from './entity.model';
import { IGeoEntity, GeoEntity } from './geoEntity.model';
import { IPhoneNumber, IAddress, PhoneNumber } from './common.model';
type TUserProfileCollection = 'user_profiles';
type TUserCollection = 'users';
type TUserMetricsCollection = 'user_metrics';
type TUserCommonCollection = TUserProfileCollection | TUserCollection;
type TUserAccountCollection = 'user_accounts';
import { faker } from '@faker-js/faker';
import { Tag } from './tag.model';
import { INotificationSettings } from './notifications.model';
import { Timestamp } from '@firebase/firestore';
// import { IStripeEntity, IPaymentMethods } from './stripe.model';
interface IUserCommon {
  // Default
  collection: TUserCommonCollection;
  publicProperties: string[];
  // Required
  email: string;
  firstName: string;
  lastName: string;
  birthday: Timestamp;
  phoneNumber: IPhoneNumber;
  // gender: TGender;
  // race: TRace;
  // Optional
  additionalDetails: Record<string, unknown>;
  middleName: string;
  otherEmails: string[];
  otherPhoneNumbers: IPhoneNumber[];
  otherAddresses: IAddress[];
  icon: string;
  bio: string;
  intro: string;
  // Computed
  displayName: string;

  /**
   * Inherited from IEntity
   *
   * uid: string;
   * objectID: string // for algolia
   * queryMetadata: string // placeholder for when results is returned by algolia
   * avatar: firebase.storeage.Reference // placeholder
   * avatarMetadata: IAvatarMetadata // placeholder
   * thumbnail: firebase.storage.Referece // placeholder
   * thumbnailMetadata: IAvatarMetadata // placeholder
   * createdAt: Timestamp
   * updatedAt: Timestamp
   * modelVersion: number // in case migration is needed
   * seed: boolean // dev
   */

  /**
  * Inherited from IGeoEntity
  *
  * geo: IGeo // for firestore geo queries
  * address: IAddress
  * _geoloc: IAlgoliaGeo // for algolia geo queries
  */

  /**
   * Inherited from ITagEntity
   *
   * tags: string[] // array of tag ids
   */

}

export interface IUserProfile extends IGeoEntity, IUserCommon {
  collection: TUserProfileCollection;
}
export class UserProfile extends GeoEntity implements IUserProfile {
  public static currentModelVersion = 2.1;
  public static Collection: TUserProfileCollection = 'user_profiles';
  public collection = UserProfile.Collection;
  public modelVersion = UserProfile.currentModelVersion;

  public publicProperties: string[] = ['firstName', 'lastName', 'displayName', 'email', 'birthday', 'gender', 'race', 'additionalDetails', 'icon', 'bio', 'intro'];

  // Required
  public email: string;
  public firstName: string;
  public lastName: string;
  public birthday: Timestamp;
  public ageCheck = false;
  public termsCheck = false;
  public phoneNumber: IPhoneNumber;
  // public gender: TGender = null;
  // public race: TRace = null;
  // Optional
  public additionalDetails: Record<string, unknown> = null;
  public middleName: string = null;
  public otherEmails: string[] = null;
  public otherAddresses: IAddress[] = null;
  public otherPhoneNumbers: IPhoneNumber[] = null;
  public icon: string;
  public bio: string;
  public intro: string;
  // Computed
  public displayName: string;


  constructor(obj: Partial<IUserProfile>) {
    super(obj);
    if ('publicProperties' in obj) {
      this.publicProperties = obj.publicProperties;
    }
    Entity.fixedProperties.forEach(k => {
      k in obj ? this[k] = obj[k] : this[k] = null;
    });
    this.publicProperties.forEach(k => {
      k in obj ? this[k] = obj[k] : this[k] = null;
    });
    this.collection = UserProfile.Collection;
    this.modelVersion = UserProfile.currentModelVersion;
  }

  public static fromCore(user: User) {
    const data: Partial<IUserProfile> = {};
    this.fixedProperties.forEach(k => {
      if (k in user) {
        data[k] = user[k];
      }
    });
    user.publicProperties.forEach(k => {
      data[k] = user[k];
    });
    return new UserProfile(data);
  }

}

export interface IUserPreferences {
  test: string;
}

export interface IUser extends IGeoEntity, IUserCommon {
  collection: TUserCollection;
  ageCheck: boolean;
  termsCheck: boolean;
}

export class User extends GeoEntity implements IUser {
  public static currentModelVersion = 2.0;
  public static Collection: TUserCollection = 'users';
  public static RequiredProperties = [
    'email',
    'firstName',
    'lastName',
    'ageCheck',
    'termsCheck',
    // 'gender',
    // 'race'
  ];
  public static DefaultAvatar = 'assets/img/default_avatar.png';
  public collection = User.Collection;
  public modelVersion = User.currentModelVersion;
  public publicProperties: string[] = ['firstName', 'lastName', 'displayName', 'email', 'birthday', 'gender', 'race', 'additionalDetails', 'icon', 'bio'];

  // Required
  public email: string;
  public firstName: string;
  public lastName: string;
  public birthday: Timestamp;
  public ageCheck = false;
  public termsCheck = false;
  public phoneNumber: IPhoneNumber;
  // Optional
  public additionalDetails: Record<string, unknown> = null;
  // public gender: TGender = null;
  // public race: TRace = null;
  public middleName: string = null;
  public otherEmails: string[] = null;
  public otherAddresses: IAddress[] = null;
  public otherPhoneNumbers: IPhoneNumber[] = null;
  public icon: string;
  // Computed
  public displayName: string;
  public bio: string;
  public intro: string;

  constructor(obj: Partial<IUser> = {}) {
    super(obj);
    const {
      // required
      email = null,
      firstName = null,
      lastName = null,
      birthday = null,
      ageCheck = false,
      termsCheck = false,
      phoneNumber = null,
      // gender = null,
      // race = null,
      // optional
      additionalDetails = null,
      middleName = null,
      otherEmails = null,
      otherPhoneNumbers = null,
      otherAddresses = null,
      icon = null,
      // computed
      displayName = null,
      publicProperties = this.publicProperties,
    } = obj;
    // required
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthday = birthday;
    this.ageCheck = ageCheck;
    this.termsCheck = termsCheck;
    this.phoneNumber = phoneNumber;
    // this.gender = gender;
    // this.race = race;
    // optional
    this.additionalDetails = additionalDetails;
    this.middleName = middleName;
    this.otherEmails = otherEmails;
    this.otherPhoneNumbers = otherPhoneNumbers;
    this.otherAddresses = otherAddresses;
    this.icon = icon;
    // computed
    this.displayName = displayName;
    if (publicProperties) this.publicProperties = publicProperties;
  }

  public static seedUser(obj: Partial<IUser> = {}) {
    const uid = null;
    const email = String(faker.internet.email()).toLowerCase();
    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();
    const displayName = firstName + ' ' + lastName;
    const phoneNumber: IPhoneNumber = {
      phoneNumber: PhoneNumber.seedPhoneNumber().phoneNumber,
      primary: true,
      phoneType: 'mobile'
    };
    return new this({
      uid,
      email,
      firstName,
      lastName,
      displayName,
      tagIds: Tag.getRandomTagIds(),
      phoneNumber,
      ageCheck: true,
      termsCheck: true,
      // gender: 'Male',
      // race: 'White',
      seed: true,
      publicProperties: ['firstName', 'lastName', 'phoneNumber', 'address'],
      ...obj
    });

  }

  public generateProfile() {
    return UserProfile.fromCore(this.toJson());
  }

}

export interface IPushDevice {
  deviceName: string;
  gcmToken: string;
}

export interface IUserAccount extends IEntity {
  algoliaApiKey: string;
  // stripeCustomerId: string;
  // stripeConnectId: string;
  email: string;
  collection: TUserAccountCollection;
  notificationSettings: INotificationSettings;
  name: string;
  gcmTokens: string[];
  defaultPaymentMethodId: string; // firestore uid of default payment method
}

export class UserAccount extends Entity implements IUserAccount {
  public static currentModelVersion = 2.0;
  public static Collection: TUserAccountCollection = 'user_accounts';
  public collection = UserAccount.Collection;
  public modelVersion = UserAccount.currentModelVersion;

  public algoliaApiKey: string;
  public stripeCustomerId: string;
  public stripeConnectId: string;
  public email: string;
  public notificationSettings: INotificationSettings;
  public name: string;
  public gcmTokens: string[];
  public defaultPaymentMethodId: string;
  // public paymentMethods: IPaymentMethods;

  constructor(obj: Partial<IUserAccount> = {}) {
    super(obj);
    const {
      algoliaApiKey = null,
      stripeCustomerId = null,
      stripeConnectId = null,
      email = null,
      notificationSettings = null,
      name = null,
      gcmTokens = null,
      defaultPaymentMethodId = null,
      // paymentMethods = null
    } = obj;
    this.algoliaApiKey = algoliaApiKey;
    this.stripeCustomerId = stripeCustomerId;
    this.stripeConnectId = stripeConnectId;
    this.email = email;
    this.notificationSettings = notificationSettings;
    this.name = name;
    this.gcmTokens = gcmTokens;
    this.defaultPaymentMethodId = defaultPaymentMethodId;
    // this.paymentMethods = paymentMethods;
  }

}

export interface IUserMetrics extends IEntity {
  collection: TUserMetricsCollection;
  orgsCreated: number;
  orgsSubscribed: number;
  orgsFollowed: number;
  needsCreated: number;
  needsCompleted: number;
  resourcesCreated: number;
  resourcesGiven: number;
  impact: number;
  tagsFollowed: number;
  donationsMade: number;
  donationsReceived: number;
  donationsMadeAmount: number;
  donationsReceivedAmount: number;
  logins: number;
  messagesSent: number;
  messagesReceived: number;
  formsCreated: number;
  formsSubmitted: number;
}

export class UserMetrics extends Entity implements IEntity {
  public static currentModelVersion = 2.0;
  public static Collection: TUserMetricsCollection = 'user_metrics';
  public collection = UserMetrics.Collection;
  public modelVersion = UserAccount.currentModelVersion;

  constructor(obj: Partial<IUserMetrics> = {}) {
    super(obj);
    [
      'orgsCreated',
      'orgsSubscribed',
      'orgsFollowed',
      'needsCreated',
      'needsCompleted',
      'resourcesCreated',
      'resourcesGiven',
      'impact',
      'tagsFollowed',
      'donationsMade',
      'donationsReceived',
      'donationsMadeAmount',
      'donationsReceivedAmount',
      'logins',
      'messagesSent',
      'messagesReceived',
      'formsCreated',
      'formsSubmitted',
    ].forEach(k => {
      !!obj[k] ? this[k] = obj[k] : this[k] = 0;
    });
  }
}
