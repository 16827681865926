// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'umanity-final',
    appId: '1:886144140477:web:6ee004fe90ad39d86ea3d4',
    storageBucket: 'umanity-final.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDyECBPX28x86j2wFwi9SGstLQ8Js-7vtk',
    authDomain: 'umanity-final.firebaseapp.com',
    messagingSenderId: '886144140477',
    measurementId: 'G-SL9DFVH1C9',
    vapidKey: 'BFJBHIhjDSrunGBCamYu3fRMi35fSNgvmp7a-rwkcPyL7IuTO5s57oLqKmVA4y2mWgWWIZbgazsYhf_AwVOdiqw',
    mapsKey: 'AIzaSyD3cNHd_jh0xos6Rzz-d2O0aK6ywBUAzHg'
  },
  algolia: {
    apiKey: 'f6ae469a37f21c466506cae9ca9e8f4e',
    appId: 'U084P8AM40'
  },
  stripe: {
    pubKeyTest: 'pk_test_51JTmu6BXrPoUpaVVrw0UuHIjDfrUA19QeZjFHmqoebMqtBsYBpyAw5Y8D1FSvK0iclIHpHSta5fPKsSqzG8TfGUR00USBTKTDw',
    pubKeyLive: 'pk_live_51JTmu6BXrPoUpaVVuEEI5TzD691FXirVX4TxHaDFlCCJPPSXDiWnufx8Nk9mjSjVJYp9tD0Ws1rWkGZlvQcieuTF00dLvSdwxU'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
