import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService, ICurrentUser } from './auth.service';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { IAlgoliaResponse } from '../models/algolia.model';
import { fromDoc, getEntityType, TEntity, TIEntity, ToEntityOptions } from '@models/models.helpers';
import { TCollection } from '@models/common.model';
import { IUserProfile, UserProfile } from '@models/user.model';
import { FirestoreService } from './firestore.service';
import { avatarCollectionMap } from '@models/entity.model';

export type AlgoliaSearchIndex = 'user_profiles' | 'org_profiles' | 'posts' | 'general_search' | 'needs' | 'resources' | 'tags' | 'tag_icons';

export interface IAlgoliaMultiResults {
  [indexName: string]: IAlgoliaResponse<TEntity>
} 
export interface IAisConfigOptions {
  indexName?: string;
  searchClient?: SearchClient;
  numberLocale?: string;
  searchFunction?: Function;
  routing?: Object;
  insightsClient?: any;
}

export const PUBLIC_API_KEY = environment.algolia.apiKey;

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {
  private cu: ICurrentUser;
  public client: SearchClient;
  public indexLabelMap = {
    org_profiles: 'Orgs',
    orgs: 'Orgs',
    resources: 'Resources',
    needs: 'Needs',
    communities: 'Communities'
  };
  constructor(
    private auth: AuthService,
    private fs: FirestoreService,
  ) {
    const defaultApiKey = (this.auth.currentUser && this.auth.currentUser.account && this.auth.currentUser.account.algoliaApiKey) ? this.auth.currentUser.account.algoliaApiKey : PUBLIC_API_KEY;
    this.setClient(defaultApiKey);
  }

  setClient(apiKey: string) {
    this.client = algoliasearch(
      environment.algolia.appId,
      apiKey
    );
  }

  public getClient(apiKey: string = null) {
    if (!apiKey) apiKey = environment.algolia.apiKey;
    return algoliasearch(
      environment.algolia.appId,
      apiKey
    );
  }

  public getIndex(apiKey: string, index: TCollection) {
    if (!apiKey) apiKey = environment.algolia.apiKey;
    return algoliasearch(
      environment.algolia.appId,
      apiKey
    ).initIndex(index);
  }

  public getInstantSearchConfig(options: IAisConfigOptions = {indexName: 'general_search'}, apiKey = null) {
    if (!options) options = {indexName: 'general_search'};
    if (!apiKey) {
      apiKey = environment.algolia.apiKey;
      // if (this.auth.currentUser && this.auth.currentUser.account && this.auth.currentUser.account.algoliaApiKey) {
      //   apiKey = this.auth.currentUser.account.algoliaApiKey;
      // }
    }
    options.searchClient = this.getClient(apiKey)
    return options;
  }


  multiResultsToEntity<T extends TEntity>(multiResponse, options: ToEntityOptions = {avatar: true, thumbnail: true, banner: false}): IAlgoliaMultiResults {
    const ret: IAlgoliaMultiResults = {};
    this.client.multipleGetObjects
    multiResponse.results.forEach(r => {
      const res: IAlgoliaResponse<T> = {
        ...r,
        indexLabel: this.indexLabelMap[r.index],
        hits: this.resultsToEntity<T>(r)
      }
      ret[r.index] = res;
    });
    return ret;
  }

  resultsToEntity<T extends TEntity>(results: any, options: ToEntityOptions = {avatar: false, thumbnail: true, banner: false}) {
    return results.hits.map(h => {
      const ent = fromDoc<T>(h) as T;
      // if (ent.collection in avatarCollectionMap && (options?.avatar || options?.thumbnail || options?.banner)) {
      //   this.fs.getAvatar(ent, options)
      // }
      return ent;
    });
  }

  aisResultsToEntity<T extends TEntity>(results: TIEntity[], options: ToEntityOptions = {avatar: false, thumbnail: true, banner: false}) {
    // return results.map(h => fromDoc<T>(h, options) as T);
    return results.map(h => this.aisResultToEntity<T>(h, options));
  }
  aisResultToEntity<T extends TEntity>(result: TIEntity, options: ToEntityOptions = {avatar: false, thumbnail: true}) {
    const ent = fromDoc<T>(result) as T;
    // if (ent.collection in avatarCollectionMap && (options?.avatar || options?.thumbnail || options.banner)) {
    //   this.fs.getAvatar(ent, options)
    // }
    return ent;
  }

  transformHits = (hits: TIEntity[], options: ToEntityOptions = {avatar: false, thumbnail: true}, op?: <T>(entity: TEntity) => TEntity) => {
    const ret = hits.map(hit => {
      switch (getEntityType(hit.uid)) {
        case 'user':
          return this.aisResultToEntity<UserProfile>(hit as IUserProfile, options);
        // case 'org':
        //   return this.aisResultToEntity<OrgProfile>(hit as IOrgProfile, options);
        // case 'need':
        //   return this.aisResultToEntity<Need>(hit as INeed, options);
        // case 'resource':
        //   return this.aisResultToEntity<Resource>(hit as IResource, options);
        default:
          console.error('transformHits unimplemented for type ', hit.collection);
          return null;
      }
    });
    if (op) {
      return ret.map(hit => op(hit));
    } else {
      return ret;
    }
  }

}
