// import { IGeo, Geo } from './geo.model';
import { IAddress, Address } from './common.model';
import { Entity } from './entity.model';
import { ITagEntity, TagEntity } from './tag.model';

export interface IAlgoliaGeo {
  lat: number;
  lng: number;
}

export interface IGeoEntity extends ITagEntity {
  // geo: IGeo;
  address: IAddress;
  _geoloc: IAlgoliaGeo;
}

export class GeoEntity extends TagEntity implements IGeoEntity {
  // public geo: Geo;
  public address: Address;
  public _geoloc: IAlgoliaGeo;
  public __position: number;
  public __queryID?: string;

  constructor(obj: Partial<IGeoEntity>) {
    super(obj);
    try {
      if (obj.address) {
        this.setAddress(obj.address);
      // } else if (obj.geo) {
        // this.setGeo(obj.geo);
      } else if (obj._geoloc) {
        this.set_geoloc(obj._geoloc);
      }
    } catch {}
  }
  public setAddress(address: IAddress) {
    if (address) {
      this.address = new Address(address.place, address.line2, address.label);
      this.set_geoloc({lat: this.address.lat, lng: this.address.lng});
      // this.setGeo(Geo.fromLatLng(this.address.lat, this.address.lng));
    }
  }

  // public setGeo(geo: IGeo) {
  //   if (geo && geo.geopoint && geo.geopoint.latitude && geo.geopoint.longitude) {
  //     this.geo = Geo.fromLatLng(geo.geopoint.latitude, geo.geopoint.longitude);
  //     this._geoloc = {
  //       lat: geo.geopoint.latitude,
  //       lng: geo.geopoint.longitude
  //     }
  //   }
  // }

  public set_geoloc(_geoloc: IAlgoliaGeo) {
    if (_geoloc) {
      this._geoloc = _geoloc;
      // if (!this.geo) {
      //   this.geo = Geo.fromLatLng(_geoloc.lat, _geoloc.lng);
      // }
    }
  }

  public toDB() {
    const tmp = super.toDB();
    return Entity.undefinedToNull(tmp); // change all undefineds to nulls
  }

}
