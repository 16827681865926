import { Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import * as fps from 'src/app/models/functions';

interface IFunkshin<TFunkshinParams, TFunkshinResponse>{
  execute: (params: TFunkshinParams) => Promise<TFunkshinResponse>;
}
class Funkshin<TFunkshinParams, TFunkshinResponse> implements IFunkshin<TFunkshinParams, TFunkshinResponse>{
  execute: (params: TFunkshinParams) => Promise<TFunkshinResponse>;
  constructor(private funkshinName: string, private functions: Functions) {
    this.execute = async (params: TFunkshinParams) => {
      try {
        console.log('invoking ', funkshinName, params);
        const res = await httpsCallable<TFunkshinParams, TFunkshinResponse>(this.functions, this.funkshinName)(params);
        return res.data as TFunkshinResponse;
      } catch (err) {
        console.error(`Error in ${funkshinName} with params ${params}: ${err}`);
        throw new Error(`${err}`);
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  functions: Functions = null;
  constructor(
    // private functions: Functions
  ) { }

  public async appInit(app: FirebaseApp) {
    this.functions = getFunctions(app);
  }

  public endpoints = {
    // auth
    isEmailAvailable: 'auth-isEmailAvailable',
    // user
    createUser: 'users-createUser',
    deleteUser: 'users-deleteUser',
    updateUser: 'users-updateUser',
    // org
    createOrg: 'orgs-createOrg',
    deleteOrg: 'orgs-deleteOrg',
    updateOrg: 'orgs-updateOrg',
    // community
    createCommunity: 'communities-createCommunity',
    deleteCommunity: 'communities-deleteCommunity',
    updateCommunity: 'communities-updateCommunity',
    // messaging
    createConversation: 'messaging-createConversation',
    onReadMessage: 'messaging-onReadMessage',
    createMessage: 'messaging-createMessage',
    // stripe
    stripeGetCustomer: 'stripe-stripeGetCustomer',
    stripeCreateConnectAccount: 'stripe-stripeCreateConnectAccount',
    stripeGetConnectAccount: 'stripe-stripeGetConnectAccount',
    stripeDeleteConnectAccount: 'stripe-stripeDeleteConnectAccount',
    stripeAddCustomerSource: 'stripe-stripeAddCustomerSource',
    stripeCreatePlaidLinkToken: 'stripe-stripeCreatePlaidLinkToken',
    stripeAddPlaidBankAccount: 'stripe-stripeAddPlaidBankAccount',
    stripeAddCard: 'stripe-stripeAddCard',
    stripeActivateUserAccount: 'stripe-stripeActivateUserAccount',
    stripeGetConnectAccountLinks: 'stripe-stripeGetConnectAccountLinks',
    stripeGetConnectLoginLink: 'stripe-stripeGetConnectLoginLink',
    stripeCreateCharge: 'stripe-stripeCreateCharge',
    stripeGetCustomerPaymentMethods: 'stripe-stripeGetCustomerPaymentMethods',
    stripeDeletePaymentMethod: 'stripe-stripeDeletePaymentMethod',
    stripeSetDefaultPaymentMethod: 'stripe-stripeSetDefaultPaymentMethod',
    // storage
    storageGetFiles: 'storage-storageGetFiles',
    storageCopyFile: 'storage-storageCopyFile',
    storageDeleteFile: 'storage-storageDeleteFile',
    // tags
    createTag: 'tags-createTag',
    addTagsToEntity: 'tags-addTagsToEntity',
    // needs
    createNeed: 'needs-createNeed',
    deleteNeed: 'needs-deleteNeed',
    updateNeed: 'needs-updateNeed',
    sponsorNeed: 'needs-sponsorNeed',
    unsponsorNeed: 'needs-unsponsorNeed',
    createNeedCommitment: 'needs-createNeedCommitment',
    deleteNeedCommitment: 'needs-deleteNeedCommitment',
    //resources
    createResource: 'resources-createResource',
    deleteResource: 'resources-deleteResource',
    updateResource: 'resources-updateResource',
  };

  // user
  public createUser = () => new Funkshin<fps.users.CreateUserParams,fps.users.CreateUserResponse>(this.endpoints.createUser, this.functions);
  public deleteUser = () => new Funkshin<fps.users.DeleteUserParams, fps.users.DeleteUserResponse>(this.endpoints.deleteUser, this.functions);
  public updateUser = () => new Funkshin<fps.users.UpdateUserParams,fps.users.UpdateUserResponse>(this.endpoints.updateUser, this.functions);

  // auth
  public isEmailAvailable = () => new Funkshin<fps.auth.IsEmailAvailableParams, fps.auth.IsEmailAvailableResponse>(this.endpoints.isEmailAvailable, this.functions);

  // org
  // public createOrg = () => new Funkshin<fps.CreateOrgParams, fps.CreateOrgResponse>(this.endpoints.createOrg, this.functions);
  // public updateOrg = () => new Funkshin<fps.CreateOrgParams, fps.UpdateOrgResponse>(this.endpoints.updateOrg, this.functions);
  // public deleteOrg = () => new Funkshin<fps.DeleteOrgParams, fps.DeleteOrgResponse>(this.endpoints.deleteOrg, this.functions);

  // community
  // public createCommunity = () => new Funkshin<fps.CreateCommunityParams, fps.CreateCommunityResponse>(this.endpoints.createCommunity, this.functions);
  // public updateOrg = () => new Funkshin<fps.CreateOrgParams, fps.UpdateOrgResponse>(this.endpoints.updateOrg, this.functions);
  // public deleteOrg = () => new Funkshin<fps.DeleteOrgParams, fps.DeleteOrgResponse>(this.endpoints.deleteOrg, this.functions);
  
  // messaging
  // public createConversation = () => new Funkshin<fps.CreateConversationParams, fps.CreateConversationResponse>(this.endpoints.createConversation, this.functions);
  // public onReadMessage = () => new Funkshin<fps.OnReadMessageParams, fps.OnReadMessageResponse>(this.endpoints.onReadMessage, this.functions);
  // public createMessage = () => new Funkshin<fps.CreateMessageParams, fps.CreateMessageResponse>(this.endpoints.createMessage, this.functions);

  // needs
  // public createNeed = () => new Funkshin<fps.CreateNeedParams, fps.CreateNeedResponse>(this.endpoints.createNeed, this.functions);
  // public deleteNeed = () => new Funkshin<fps.DeleteNeedParams, fps.DeleteNeedResponse>(this.endpoints.deleteNeed, this.functions);
  // public updateNeed = () => new Funkshin<fps.UpdateNeedParams, fps.UpdateNeedResponse>(this.endpoints.updateNeed, this.functions);
  // public sponsorNeed = () => new Funkshin<fps.SponsorNeedParams, fps.SponsorNeedResponse>(this.endpoints.sponsorNeed, this.functions);
  // public unsponsorNeed = () => new Funkshin<fps.UnSponsorNeedParams, fps.UnSponsorNeedResponse>(this.endpoints.unsponsorNeed, this.functions);
  // public createNeedCommitment = () => new Funkshin<fps.CreateNeedCommitmentParams, fps.CreateNeedCommitmentResponse>(this.endpoints.createNeedCommitment, this.functions);
  // public deleteNeedCommitment = () => new Funkshin<fps.DeleteNeedCommitmentParams, fps.DeleteNeedCommitmentResponse>(this.endpoints.deleteNeedCommitment, this.functions);
  //resources
  // public createResource = () => new Funkshin<fps.CreateResourceParams, fps.CreateResourceResponse>(this.endpoints.createResource, this.functions);
  // public deleteResource = () => new Funkshin<fps.DeleteResourceParams, fps.DeleteResourceResponse>(this.endpoints.deleteResource, this.functions);
  // public updateResource = () => new Funkshin<fps.UpdateResourceParams, fps.UpdateResourceResponse>(this.endpoints.updateResource, this.functions);

  // stripe
  // public stripeCreateConnectAccount = () => new Funkshin<fps.StripeCreateConnectAccountParams, fps.StripeCreateConnectAccountResponse>(this.endpoints.stripeCreateConnectAccount, this.functions);
  // public stripeGetConnectAccount = () => new Funkshin<fps.StripeGetConnectAccountParams, fps.StripeGetConnectAccountResponse>(this.endpoints.stripeGetConnectAccount, this.functions);
  // public stripeDeleteConnectAccount = () => new Funkshin<fps.StripeDeleteConnectAccountParams, fps.StripeDeleteConnectAccountResponse>(this.endpoints.stripeDeleteConnectAccount, this.functions);
  // public stripeGetConnectAccountLinks = () => new Funkshin<fps.StripeGetConnectAccountLinksParams, fps.StripeGetConnectAccountLinksResponse>(this.endpoints.stripeGetConnectAccountLinks, this.functions);
  // public stripeGetConnectLoginLink = () => new Funkshin<fps.StripeGetConnectLoginLinkParams, fps.StripeGetConnectLoginLinkResponse>(this.endpoints.stripeGetConnectLoginLink, this.functions);
  // public stripeGetCustomer = () => new Funkshin<fps.StripeGetCustomerParams, fps.StripeGetCustomerResponse>(this.endpoints.stripeGetCustomer, this.functions);
  // public stripeCreatePlaidLinkToken = () => new Funkshin<fps.StripeCreatePlaidLinkTokenParams, fps.StripeCreatePlaidLinkTokenResponse>(this.endpoints.stripeCreatePlaidLinkToken, this.functions);
  // public stripeAddPlaidBankAccount = () => new Funkshin<fps.StripeAddPlaidBankAccountParams, fps.StripeAddPlaidBankAccountResponse>(this.endpoints.stripeAddPlaidBankAccount, this.functions);
  // public stripeGetCustomerPaymentMethods = () => new Funkshin<fps.StripeGetCustomerPaymentMethodsParams, fps.StripeGetCustomerPaymentMethodsResponse>(this.endpoints.stripeGetCustomerPaymentMethods, this.functions);
  // public stripeAddCard = () => new Funkshin<fps.StripeAddCardParams, fps.StripeAddCardResponse>(this.endpoints.stripeAddCard, this.functions);
  // public stripeDeletePaymentMethod = () => new Funkshin<fps.StripeDeletePaymentMethodParams, fps.StripeDeletePaymentMethodParams>(this.endpoints.stripeDeletePaymentMethod, this.functions);
  // public stripeSetDefaultPaymentMethod = () => new Funkshin<fps.StripeSetDefaultPaymentMethodParams, fps.StripeSetDefaultPaymentMethodResponse>(this.endpoints.stripeSetDefaultPaymentMethod, this.functions);

  // tags
  // public createTag = () => new Funkshin<fps.CreateTagParams, fps.CreateTagResponse>(this.endpoints.createTag, this.functions);
  // public addTagsToEntity = () => new Funkshin<fps.AddTagsToEntityParams, fps.AddTagsToEntityParams>(this.endpoints.addTagsToEntity, this.functions);

  // storage
  // public storageGetFiles = () => new Funkshin<fps.StorageGetFilesParams, fps.StorageGetFilesResponse>(this.endpoints.storageGetFiles, this.functions);
  // public storageCopyFile = () => new Funkshin<fps.StorageCopyFileParams, fps.StorageCopyFileResponse>(this.endpoints.storageCopyFile, this.functions);
  // public storageDeleteFile = () => new Funkshin<fps.StorageDeleteFileParams, fps.StorageDeleteFileResponse>(this.endpoints.storageDeleteFile, this.functions);
}
