import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FirebaseApp } from 'firebase/app';
import { Auth, browserLocalPersistence, getAuth, indexedDBLocalPersistence, initializeAuth, inMemoryPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { authState } from 'rxfire/auth';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { FunctionsService } from './functions.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private authService: AuthService,
    private sessionService: SessionService
  ) { }

  appInit(app: FirebaseApp) {
    return new Promise(async (resolve, reject) => {
      console.log('initing auth');
      await this.firestoreService.appInit(app);
      await this.functionsService.appInit(app);
      const persistence = Capacitor.isNativePlatform() ? indexedDBLocalPersistence : browserLocalPersistence;
      const auth = initializeAuth(app, {
        persistence
      });
      const currentUser = await this.authService.appInit(app);
      await this.sessionService.appInit(app);
      resolve(currentUser);
      // resolve(null);
    })
  }


}


