import { Injectable } from '@angular/core';
import { TCollection } from "@models/common.model";
import { avatarCollectionMap } from "@models/entity.model";
import { fromDoc, getEntityCollection, getStorageDir, TEntity, ToEntityOptions } from "@models/models.helpers";
import { Firestore, getFirestore, doc, collection, CollectionReference, DocumentReference, Query, query, QueryConstraint, initializeFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { switchMap, take } from "rxjs/operators";
import { collectionData, docData } from 'rxfire/firestore';
import { FirebaseApp } from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  fs: Firestore = null;
  constructor(
  ) {
  }

  public async appInit(app: FirebaseApp) {
    this.fs = getFirestore(app);
  }

  public colToEntity$ = <T extends TEntity>(
    col: TCollection, 
    queryConstraints: QueryConstraint[] = [],
    options: ToEntityOptions = {avatar: false, thumbnail: false, banner: false},
  ) => {
    let q: Query<T>;
    if (queryConstraints.length) {
      q = query(collection(this.fs, col) as CollectionReference<T>, ...queryConstraints);
    } else {
      q = query(collection(this.fs, col) as CollectionReference<T>);
    }
    return collectionData(q).pipe(
      switchMap(docs => {
        return Promise.all(docs.map(async document => {
          if (!doc) return null;
          try {
            const ent = fromDoc<T>(document as T);
            return ent;
          } catch (err) {
            throw new Error(`${err}`);
          }
        }))
      })
    )
  }

  public getCollection = <T extends TEntity>(
    col: TCollection,
    queryConstraints: QueryConstraint[] = [],
    options: ToEntityOptions = {avatar: false, thumbnail: false, banner: false}
  ) => {
    return this.colToEntity$<T>(col, queryConstraints, options).pipe(take(1)).toPromise();
  }


  public docToEntity$ = <T extends TEntity>(path: string, options: ToEntityOptions = {
    avatar: false,
    thumbnail: false,
    banner: false,
  }, source?: string) => {
    return docData(doc(this.fs, path) as DocumentReference<T>);
  }

  public getDoc = <T extends TEntity>(path: string, options: ToEntityOptions = {
    avatar: false,
    thumbnail: false,
    banner: false,
  }, source?: string) => {
    return this.docToEntity$<T>(path, options).pipe(take(1)).toPromise();
  }

  // public getAvatar = async (entity: TEntity, options: ToEntityOptions = {avatar: false, thumbnail: false, banner: false}) => {
  //   const uid = entity.uid;
  //   const {avatar, thumbnail, banner} = options;
  //   const col = entity.collection;
  //   if (!avatar && !thumbnail && !banner) {
  //     return;
  //   }
  //   // switch (col) {
  //   //   case 'conversations':
  //   //     const conversation = entity as Conversation;
  //   //     await Promise.all(conversation.memberIds.map(async memberId => {
  //   //       const thumb = await this.downloadAvatar(memberId, 'thumbnail');
  //   //       conversation.members[memberId].thumbnail = thumb;
  //   //       if (conversation.members[memberId].orgId) {
  //   //         conversation.members[memberId].orgThumbnail = await this.downloadAvatar(conversation.members[memberId].orgId);
  //   //       }
  //   //     }));
  //   //     break;
  //   //   case 'needs':
  //   //     const need = entity as Need;
  //   //     if (need?.sponsors) {
  //   //       Object.keys(need.sponsors).forEach(async uid => {
  //   //         try {
  //   //           need.sponsors[uid].avatar = await this.downloadAvatar(uid, 'thumbnail');
  //   //         } catch {
  //   //           need.sponsors[uid].avatar = 'assets/img/default_avatar.png';
  //   //         }
  //   //       })
  //   //     }
  //   //     if (need?.commitments) {
  //   //       Object.keys(need.commitments).forEach(async uid => {
  //   //         try {
  //   //           need.commitments[uid].avatar = await this.downloadAvatar(uid, 'thumbnail');
  //   //         } catch {
  //   //           need.commitments[uid].avatar = 'assets/img/default_avatar.png';
  //   //         }
  //   //       });
  //   //     }
  //   //     break;
  //   // }
  //   if (getEntityCollection(entity.uid) in avatarCollectionMap) {
  //     if (avatar) {
  //       entity.avatar = (await this.downloadAvatar(entity.uid, 'avatar')) || 'assets/img/default_avatar.png';
  //     }
  //     if (thumbnail) {
  //       entity.thumbnail = (await this.downloadAvatar(entity.uid, 'thumbnail'))  || 'assets/img/default_avatar.png';
  //     }
  //     if (banner) {
  //       entity.banner = await this.downloadAvatar(entity.uid, 'banner');
  //     }
  //   }
  // }

  // public downloadAvatar = async (uid: string, type: 'avatar' | 'thumbnail' | 'banner' = 'avatar') => {
  //   // const col = getEntityCollection(uid);
  //   // if (!col) return null;
  //   const path = getStorageDir(uid);
  //   const storage = getStorage();
  //   const filename = `${path}/avatars/${type}.jpg`;
  //   const reference = ref(storage, filename);
  //   try {
  //     const downloadUrl = await getDownloadURL(reference);
  //     return downloadUrl;
  //   } catch (err) {
  //     return null;
  //   }
  // }

}
